import { RequiringActionFeatureType, RequiringActionType } from '@paid-ui/constants';
import { ContractRole, ContractState, DefaultContractActionType } from '@paid-ui/enums/contract';
import { PartyLinkingStatus } from '@paid-ui/enums/party';
import { featureManager } from '@paid-ui/models/feature';

import { contractManager } from '../model';

export const saveDefaultActions = () => {
  const { enablePeriodicContracts } = featureManager;
  const {
    contractState,
    hasAllSigned,
    isInviter,
    superintendentParty,
    architectParty,
    statuses,
    permissions,
    requiringActions,
    inviteeParty,
  } = contractManager;
  const { isPrimeContract, isDraftContract, isOffPlatformContract } = statuses;
  const {
    canCreateClaim,
    canCreateVariation,
    canSendCommencementNotice,
    canSendCompletionNotice,
    canResendInvitation,
    canViewContract,
    canAbandonContract,
    canRevokeContract,
  } = permissions;
  const isContractSubmitted = contractState === ContractState.SUBMITTED && !isDraftContract;

  const hasSubmitClaimRequiringAction = requiringActions.some(
    (action) =>
      action.action === RequiringActionType.SUBMIT_CLAIM &&
      action.featureItemType === RequiringActionFeatureType.CONTRACT,
  );

  const canResendInvitationForPeriodicSecondaryContracts =
    !isPrimeContract &&
    isContractSubmitted &&
    isInviter &&
    inviteeParty?.linkingStatus === PartyLinkingStatus.LINKED;

  const canResendInvitationToOwner =
    isPrimeContract &&
    isContractSubmitted &&
    isInviter &&
    inviteeParty?.linkingStatus &&
    inviteeParty?.linkingStatus === PartyLinkingStatus.UNLINKED;

  const canResendInvitationToSuperintendent =
    isPrimeContract &&
    isContractSubmitted &&
    isInviter &&
    superintendentParty?.linkingStatus &&
    superintendentParty?.linkingStatus === PartyLinkingStatus.UNLINKED &&
    superintendentParty?.role?.role === ContractRole.SUPERINTENDENT;

  const canResendInvitationToArchitect =
    isPrimeContract &&
    isContractSubmitted &&
    isInviter &&
    architectParty?.linkingStatus &&
    architectParty?.linkingStatus === PartyLinkingStatus.UNLINKED &&
    architectParty?.role?.role === ContractRole.ARCHITECT;

  contractManager.defaultActions = [
    {
      type: DefaultContractActionType.NEW_CLAIM,
      text: 'Claim',
      hidden: !canCreateClaim || hasSubmitClaimRequiringAction,
      hasIcon: true,
    },
    {
      type: DefaultContractActionType.NEW_VARIATION,
      text: 'Variation',
      hidden: !canCreateVariation,
      hasIcon: true,
    },
    {
      type: DefaultContractActionType.SET_COMMENCEMENT,
      text: 'Notice',
      hidden: !canSendCommencementNotice,
      hasIcon: true,
    },
    {
      type: DefaultContractActionType.SET_COMPLETION,
      text: 'Notice',
      hidden: !canSendCompletionNotice,
      hasIcon: true,
    },
    {
      type: DefaultContractActionType.RESEND_INVITATION,
      text: 'Resend invitation',
      hidden: enablePeriodicContracts || !canResendInvitation,
      prime: true,
    },
    {
      type: DefaultContractActionType.EDIT_DRAFT,
      text: 'Edit',
      hidden: !isDraftContract,
      prime: true,
    },
  ];

  contractManager.dropdownActions = [
    {
      type: DefaultContractActionType.RESEND_INVITATION,
      text: `Resend invitation`,
      hidden: !enablePeriodicContracts || !canResendInvitationForPeriodicSecondaryContracts,
    },
    {
      type: DefaultContractActionType.REINVITE_SUPERINTENDENT,
      text: 'Re-invite Superintendent',
      hidden: !enablePeriodicContracts || !canResendInvitationToSuperintendent,
    },
    {
      type: DefaultContractActionType.REINVITE_ARCHITECT,
      text: 'Re-invite Architect',
      hidden: !enablePeriodicContracts || !canResendInvitationToArchitect,
    },
    {
      type: DefaultContractActionType.RESEND_INVITATION,
      text: 'Re-invite Property owner / Principal',
      hidden: !enablePeriodicContracts || !canResendInvitationToOwner,
    },
    {
      type: DefaultContractActionType.VIEW_CONTRACT,
      text:
        hasAllSigned || isOffPlatformContract ? 'View signed contract' : 'View unsigned contract',
      hidden: !canViewContract,
    },
    {
      type: DefaultContractActionType.HALT_SIGNING_CONTRACT,
      text: 'Halt signing contract',
      hidden: !canRevokeContract,
    },
    {
      type: DefaultContractActionType.ABANDON_CONTRACT,
      text: 'Abandon contract',
      hidden: !canAbandonContract,
    },
    {
      type: DefaultContractActionType.DELETE_DRAFT,
      text: 'Delete unshared draft',
      hidden: !isDraftContract,
    },
  ];
};
