import { PaymentClaimType } from '@paid-ui/constants';
import { ClaimSource, ClaimStatus } from '@paid-ui/enums/claim';
import { ContractState } from '@paid-ui/enums/contract';
import { EvidenceCategory } from '@paid-ui/enums/evidence';
import { DefaultPaymentActionType, PaymentSecuredStatus } from '@paid-ui/enums/payment';
import { WorkItemType } from '@paid-ui/enums/work-item';
import { featureManager } from '@paid-ui/models/feature';
import { type Payment, paymentRequiringActionFeatureTypeSet } from '@paid-ui/models/payment';
import { projectManager } from '@paid-ui/models/project';
import { userManager } from '@paid-ui/models/user';
import { type PaymentStage } from '@paid-ui/types/payments';
import { utc2Mel } from '@paid-ui/utils/datetime';
import { isNotNil } from '@paid-ui/utils/filters';
import { getAccountProvisioned } from '@paid-ui/utils/parties';
import { getStageName } from '@paid-ui/utils/payments';
import { sortBy } from 'lodash';

import { contractManager } from '../model';

export const getDisplayStageName = (payment: PaymentStage) => {
  const { source, workItemPayment, type } = payment.claim ?? {};

  if (type === PaymentClaimType.RETENTION) {
    return 'Retention';
  }

  if (workItemPayment) {
    const endDate = workItemPayment.endDate;

    if (source === ClaimSource.OFF_PLATFORM) {
      return getStageName(payment);
    }

    return endDate ? utc2Mel(endDate).format('MMMM') : 'Periodic Claim';
  }

  return getStageName(payment);
};

export const formatProgressPayment = (
  payment: PaymentStage,
  options: {
    index: number;
    isProvisional?: boolean;
    snapshot?: Record<string, any>;
  },
): Payment => {
  const { index, isProvisional = false, snapshot = null } = options;
  const { amount = 0, secured, claim, parent, linked, description } = payment;

  const { enableLinking, enableEarlyRelease, enableTransactionAccount } = featureManager;
  const { isAccountReady } = userManager;
  const { primeContract, requiringActions: projectRequiringActions } = projectManager;

  const paymentHistories = claim?.paymentHistory ?? [];
  const claimHistories = claim?.stateHistory ?? [];
  const histories = sortBy([...claimHistories, ...paymentHistories], (item) =>
    item.createdAt.valueOf(),
  ).reverse();

  const {
    id: contractId,
    contractName,
    fullContractName,
    suburbAndState,
    contractType,
    formattedAddress,
    hasAllSigned,

    price,
    totalNumberOfPayments,
    numberOfClaimedPayments,

    partyName,
    currentParty,
    theOtherPartyName,
    theOtherParty,
    isPayer,
    isPayee,
    isInviter,
    isInvitee,
    isSuperintendent,
    isArchitect,
    payeeParty,
    payerParty,
    inviterParty,
    inviteeParty,
    participantParties,
    superintendentParty,
    architectParty,

    statuses,
    requirements,
  } = contractManager;

  const { isPeriodic, isDraftContract, isPrimeContract, isContractExecuted, isOneLastPaymentLeft } =
    statuses;

  const isPrimeContractExecuted = primeContract?.contractState === ContractState.EXECUTED;
  const {
    workItemPaymentNumber = '',
    workItemPaymentLines = [],
    endDate = '',
  } = payment.claim?.workItemPayment ?? {};

  const displayName = getDisplayStageName(payment);
  const lineWorkItems = workItemPaymentLines.filter(
    ({ workItem }) => workItem.type === WorkItemType.LINE,
  );
  const variationWorkItems = workItemPaymentLines.filter(
    ({ workItem }) => workItem.type === WorkItemType.VARIATION,
  );

  const hasLinks = enableLinking && isNotNil(linked) && linked.length > 0;
  const hasParent = enableLinking && Boolean(parent?.id);
  const hasClaim = Boolean(claim?.id);
  const claimAmount = claim?.amount ?? 0;
  const claimState = claim?.state;
  const variations = claim?.variations ?? [];
  const adjustments = claim?.adjustments ?? [];
  const numberOfVariations = variations.length;
  const numberOfAdjustments = adjustments.length;
  const dueDate = claim?.dueDate;
  const dueInDays = claim?.dueInDays ?? 0;
  const paymentTermsInDays = claim?.paymentTermsInDays ?? 0;
  const earlyReleaseDiscountRate = claim?.earlyReleaseDiscountRate;
  const breakdown = claim?.paymentBreakdown;
  const earlyReleaseRequests = claim?.earlyReleaseRequests ?? [];
  const {
    securedUnreleased = 0,
    releasedUnpaid = 0,
    totalHistoryPaid = 0,
    discount = 0,
    totalPayable,
    unsecuredUnpaid = 0,
  } = breakdown ?? {};
  const isRetention = claim?.type === PaymentClaimType.RETENTION;

  const totalPaid = totalHistoryPaid + releasedUnpaid + discount;
  const payableTotal = isPeriodic ? totalPayable?.amount ?? 0 : claimAmount;

  const isOffPlatform = claim?.source === ClaimSource.OFF_PLATFORM;
  const isApproved = claim?.state === ClaimStatus.APPROVED;
  const isAssessed =
    !!claim?.state && [ClaimStatus.APPROVED, ClaimStatus.PAID].includes(claim.state);
  const isOverdue = dueInDays < 0;

  const isFullPaid = claim?.state === ClaimStatus.PAID;
  const isPartPaid =
    claim?.state !== ClaimStatus.PAID && totalPaid > 0 && totalPaid <= payableTotal;
  const isPaid = isPartPaid || isFullPaid;

  const isFullySecured = claimAmount
    ? !isPaid && securedUnreleased + releasedUnpaid >= payableTotal
    : secured === PaymentSecuredStatus.SECURED;

  const isPartSecured = claimAmount
    ? !isPaid && !isFullySecured && securedUnreleased > 0
    : secured === PaymentSecuredStatus.PARTIALLY_SECURED;
  const isSecured = isPartSecured || isFullySecured;

  const requiringActions = projectRequiringActions.filter(
    ({ featureItemType, data }) =>
      paymentRequiringActionFeatureTypeSet.has(featureItemType) &&
      data?.progressPaymentId &&
      payment.id === data.progressPaymentId,
  );

  const depositInsuranceRequired = requirements.some(
    ({ status, type }) => status === 'PENDING' && type === 'DEPOSIT_INSURANCE',
  );
  const isDepositPayment = payment.tags?.includes('DEPOSIT') ?? false;

  // When no claim is created, payee can only claim for deposit stage.
  const canClaimDepositOnly =
    !isPrimeContract ||
    numberOfClaimedPayments > 0 ||
    !depositInsuranceRequired ||
    (numberOfClaimedPayments === 0 && isDepositPayment);

  // When only last payment is left unapproved, payee can claim for last payment.
  const canClaimFinalPayment =
    isOneLastPaymentLeft || (!isOneLastPaymentLeft && index !== totalNumberOfPayments - 1);

  const canClaim =
    isPayee &&
    !isDraftContract &&
    !isPeriodic &&
    isContractExecuted &&
    hasAllSigned &&
    !hasClaim &&
    canClaimDepositOnly &&
    canClaimFinalPayment;

  const canEdit = isPayee && claim?.state === ClaimStatus.PROVISIONAL && isContractExecuted;

  const canUpdate =
    isInviter &&
    !isDraftContract &&
    isPeriodic &&
    isOffPlatform &&
    isContractExecuted &&
    hasAllSigned &&
    hasClaim;

  const canLink =
    enableLinking &&
    isPayer &&
    !isDraftContract &&
    !isPrimeContract &&
    !isPeriodic &&
    isPrimeContractExecuted &&
    isContractExecuted &&
    hasAllSigned &&
    !hasClaim &&
    !hasParent &&
    !isFullySecured;

  const canSecureBase =
    enableEarlyRelease &&
    isPayer &&
    isAccountReady &&
    !isDraftContract &&
    isContractExecuted &&
    hasAllSigned;

  const canSecureStaged =
    canSecureBase &&
    !isPeriodic &&
    !hasClaim &&
    !hasParent &&
    !isFullPaid &&
    (secured === PaymentSecuredStatus.NOT_SECURED ||
      secured === PaymentSecuredStatus.PARTIALLY_SECURED);

  const canSecurePeriodic =
    canSecureBase &&
    isPeriodic &&
    isPrimeContract &&
    isAssessed &&
    !isOffPlatform &&
    !isFullPaid &&
    unsecuredUnpaid > 0 &&
    (secured === PaymentSecuredStatus.NOT_SECURED ||
      secured === PaymentSecuredStatus.PARTIALLY_SECURED);

  const canSecureNotAssessed =
    canSecureBase &&
    isPeriodic &&
    isPrimeContract &&
    !isOffPlatform &&
    claim?.type !== PaymentClaimType.RETENTION &&
    (claim?.state === ClaimStatus.PROVISIONAL || claim?.state === ClaimStatus.SUBMITTED);

  const canSecure = canSecureStaged || canSecurePeriodic || canSecureNotAssessed;

  const canRelease =
    enableEarlyRelease &&
    !isPrimeContract &&
    isPayee &&
    isAccountReady &&
    !isDraftContract &&
    isContractExecuted &&
    hasAllSigned &&
    hasClaim &&
    claimState === ClaimStatus.APPROVED &&
    !isFullPaid &&
    Number(dueInDays) > 0 &&
    isNotNil(earlyReleaseDiscountRate) &&
    (secured === PaymentSecuredStatus.SECURED ||
      secured === PaymentSecuredStatus.PARTIALLY_SECURED);

  const canMarkAsPaid =
    isPayee &&
    !isDraftContract &&
    !isPeriodic &&
    isContractExecuted &&
    hasAllSigned &&
    hasClaim &&
    isOffPlatform &&
    isApproved;

  const isPayeeAccountReady = getAccountProvisioned(payeeParty);
  const isPayReady = isPayeeAccountReady && enableTransactionAccount;
  const canPay =
    claim?.state === ClaimStatus.APPROVED && isPayReady && unsecuredUnpaid > 0 && !isFullySecured;
  const canResubmit = claim?.state === ClaimStatus.RESUBMITTED;
  const canReview =
    claim?.type === PaymentClaimType.RETENTION &&
    (isSuperintendent || isPayer) &&
    !!claim?.state &&
    [ClaimStatus.SUBMITTED, ClaimStatus.RESUBMITTED].includes(claim?.state);

  const earlyReleaseDiscounts = (claim?.discounts ?? []).filter(
    (discount) => discount.type === 'EARLY_RELEASE_DISCOUNT',
  );

  const evidences = sortBy(claim?.evidences || [], (evidence) => evidence.fileName);

  let displayAmount = hasClaim ? claimAmount : amount;

  if (isPeriodic && isAssessed) {
    const totalAssessedAmount = claim.workItemPayment?.totalAssessedAmount;
    displayAmount =
      typeof totalAssessedAmount === 'number'
        ? totalAssessedAmount
        : Number(totalAssessedAmount?.excludeGstAmount);
  }

  return {
    ...payment,
    id: payment.id ?? '',
    displayName,
    displayAmount,
    hasLinks,
    hasParent,
    hasClaim,
    description,
    claimId: claim?.id ?? '',
    claimAmount: claimAmount,
    taxableAmount: claim?.taxableAmount ?? {
      amount: 0,
      excludeGstAmount: 0,
      gstAmount: 0,
    },
    retentionAmount: claim?.retentionAmount ?? 0,
    retentionTaxableAmount: claim?.retentionTaxableAmount ?? {
      amount: 0,
      excludeGstAmount: 0,
      gstAmount: 0,
    },
    claimState: claimState,
    dueDate,
    dueInDays,
    disabled: !canClaim,
    submissionDueDate: claim?.submissionDueDate,
    consolidatedClaimEndDate: endDate,
    workItemPayment: claim?.workItemPayment,
    workItemPaymentNumber,
    lineWorkItems,
    variationWorkItems,
    paymentTermsInDays,
    earlyReleaseDiscountRate,
    earlyReleaseRequests,
    breakdown,
    adjustments,
    variations,
    numberOfVariations: numberOfVariations + numberOfAdjustments,
    numberOfLinkedPayments: linked?.length ?? 0,
    earlyReleaseDiscounts,
    numberOfDiscounts: earlyReleaseDiscounts.length,
    claimHistories,
    paymentHistories,
    histories,
    evidences,
    documents: evidences.filter((evidence) => evidence.category === EvidenceCategory.DOCUMENT),
    photosAndVideos: evidences.filter((evidence) =>
      [EvidenceCategory.PHOTOGRAPH, EvidenceCategory.VIDEO].includes(evidence.category),
    ),
    contract: {
      id: contractId,
      contractType,
      contractName,
      fullContractName,
      suburbAndState,
      price,
      address: formattedAddress,
      partyName,
      party: currentParty,
      theOtherPartyName,
      theOtherParty,
      isPayer,
      isPayee,
      isInviter,
      isInvitee,
      isSuperintendent,
      isArchitect,
      payerParty,
      payeeParty,
      inviteeParty,
      inviterParty,
      participantParties,
      superintendentParty,
      architectParty,
      isContractExecuted,
    },
    requiringActions,
    defaultActions: [
      {
        type: DefaultPaymentActionType.CLAIM,
        text: 'Claim',
        hidden: !canClaim,
      },
      {
        type: DefaultPaymentActionType.UPDATE,
        text: 'Update',
        hidden: !canUpdate,
      },
      {
        type: DefaultPaymentActionType.LINK,
        text: 'Link',
        hidden: !canLink,
      },
      {
        type: DefaultPaymentActionType.SECURE,
        text: 'Secure',
        hidden: !canSecure,
      },
      {
        type: DefaultPaymentActionType.RELEASE,
        text: 'Get InstaPaid',
        hidden: !canRelease,
      },
      {
        type: DefaultPaymentActionType.MARK_AS_PAID,
        text: 'Mark as paid',
        hidden: !canMarkAsPaid,
      },
    ],
    statuses: {
      isRetention,
      isPeriodic,
      isOffPlatform,
      isApproved,
      isProvisional,
      isAssessed,
      isFullPaid,
      isFullySecured,
      isOverdue,
      isPaid,
      isPartPaid,
      isPartSecured,
      isSecured,
    },
    permissions: {
      canClaim,
      canUpdate,
      canLink,
      canMarkAsPaid,
      canRelease,
      canSecure,
      canResubmit,
      canReview,
      canPay,
      canEdit,
    },
    filters: new Set(),
    snapshot,
  };
};
