import { ContractRole } from '@paid-ui/enums/contract';
import { PartyLinkingStatus, PartyRole, PayType } from '@paid-ui/enums/party';
import { SigningStatus } from '@paid-ui/enums/signatory';
import { ApprovalStatus } from '@paid-ui/enums/snapshot';
import { type ContractParticipant, type ContractParticipantParty } from '@paid-ui/types/parties';
import {
  getArchitectParty,
  getContractParticipantParties,
  getInvitee,
  getInviteeParty,
  getInviter,
  getInviterParty,
  getLastReviewParty,
  getPayee,
  getPayeeParty,
  getPayer,
  getPayerParty,
  getSuperintendentParty,
} from '@paid-ui/utils/parties';
import { sumBy } from 'lodash';

import { contractManager } from '../model';

export const saveParticipants = (participants: ContractParticipant[] = []) => {
  const payee = getPayee(participants);
  const payer = getPayer(participants);
  const inviter = getInviter(participants);
  const invitee = getInvitee(participants);

  contractManager.payee = payee;
  contractManager.payer = payer;
  contractManager.invitee = invitee;
  contractManager.inviter = inviter;
  contractManager.participants = participants;
};

export const saveParticipantParties = (parties: ContractParticipantParty[]) => {
  const inviteeParty = getInviteeParty(parties);
  const inviterParty = getInviterParty(parties);
  const payeeParty = getPayeeParty(parties);
  const payerParty = getPayerParty(parties);
  const superintendent = getSuperintendentParty(parties);
  const architect = getArchitectParty(parties);
  const lastReviewParty = getLastReviewParty(parties);
  const { currentParty, theOtherParty } = getContractParticipantParties(parties);

  const isInvitee = currentParty?.invitationType === PartyRole.INVITEE;
  const isInviter = currentParty?.invitationType === PartyRole.INVITER;
  const isPayee = currentParty?.payType === PayType.PAYEE;
  const isPayer = currentParty?.payType === PayType.PAYER;
  const isSuperintendent = currentParty?.role?.role === ContractRole.SUPERINTENDENT;
  const isArchitect = currentParty?.role?.role === ContractRole.ARCHITECT;

  const isPayerHasAccount = !!payerParty?.account;
  const numberOfParticipants =
    (payeeParty?.usersInfo?.length ?? 0) +
    (payerParty?.usersInfo?.length ?? 0) +
    (superintendent?.usersInfo?.length ?? 0);

  // TODO: Joined list should be users not parties
  const joinedList = parties.filter((party) => party.linkingStatus !== PartyLinkingStatus.UNLINKED);

  const numberOfJoined = sumBy(joinedList, (item) => item.usersInfo?.length ?? 0);
  const numberOfSigned = sumBy(parties, (item) => {
    const signed = item.usersInfo?.filter((user) => user.signingStatus === SigningStatus.SIGNED);
    return signed?.length ?? 0;
  });
  const approvedList = parties.filter(
    (party) => party.snapshotStatus?.approvalStatus === ApprovalStatus.ACCEPT,
  );
  const numberOfApproved = sumBy(approvedList, (item) => item.usersInfo?.length ?? 0);

  contractManager.partyName = currentParty?.displayName ?? '';
  contractManager.theOtherParty = theOtherParty ?? null;
  contractManager.theOtherPartyName = theOtherParty?.displayName ?? '';

  contractManager.payeeParty = payeeParty;
  contractManager.payerParty = payerParty;
  contractManager.inviteeParty = inviteeParty;
  contractManager.inviterParty = inviterParty;

  contractManager.architectParty = architect;
  contractManager.architectName = architect?.displayName ?? '';

  contractManager.superintendentParty = superintendent;
  contractManager.superintendentPartyName = superintendent?.displayName ?? '';

  contractManager.isPayee = isPayee;
  contractManager.isPayer = isPayer;
  contractManager.isInvitee = isInvitee;
  contractManager.isInviter = isInviter;
  contractManager.isArchitect = isArchitect;
  contractManager.isSuperintendent = isSuperintendent;
  contractManager.isPayerHasAccount = isPayerHasAccount;
  contractManager.participantParties = parties;
  contractManager.numberOfParticipants = numberOfParticipants;
  contractManager.numberOfJoined = numberOfJoined;
  contractManager.numberOfSigned = numberOfSigned;
  contractManager.numberOfApproved = numberOfApproved;

  contractManager.currentParty = currentParty;
  contractManager.lastReviewParty = lastReviewParty;
};
