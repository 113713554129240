import {
  DrawerClose,
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  DrawerRoot,
} from '@paid-ui/components/drawer';
import { LoadingIndicator } from '@paid-ui/components/loading-indicator';
import { StepFormContainer, StepFormContent, StepFormHeader } from '@paid-ui/components/step-form';
import { type UserGroupRelation } from '@paid-ui/enums/user';
import dynamic from 'next/dynamic';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';

import { closeNestedFlyout } from './actions';
import { flyoutManager, NestedFlyoutType } from './model';

const MobileVerificationForm = dynamic(() => import('@paid-ui/forms/mobile-verification'), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});
const EditPersonalInfo = dynamic(() => import('@paid-ui/forms/edit-personal'), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});
const CompletePersonalInfo = dynamic(() => import('@paid-ui/forms/complete-personal'), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});
const CompleteBusinessInfo = dynamic(() => import('@paid-ui/forms/complete-business'), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});
const CompleteTrusteesInfo = dynamic(() => import('@paid-ui/forms/complete-trustees'), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});
const EditBusinessInfo = dynamic(() => import('@paid-ui/forms/edit-business'), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});
const EditTrusteesInfo = dynamic(() => import('@paid-ui/forms/edit-trustees'), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});
const EditIndividualTrustees = dynamic(() => import('@paid-ui/forms/edit-individual-trustees'), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});
const EditSignatoryForm = dynamic(() => import('@paid-ui/forms/edit-signatory'), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});
const SecurePaidDoc = dynamic(() => import('@paid-ui/blocks/securepaid-doc'), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});
const EvidenceMapView = dynamic(() => import('@paid-ui/blocks/evidence-map-view'), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});
const RetentionClaimDetails = dynamic(() => import('@paid-ui/blocks/RetentionClaimDetails'), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});
const ReviewClaimDetailsWithRetention = dynamic(
  () => import('@paid-ui/blocks/ReviewClaimDetailsWithRetention'),
  {
    ssr: false,
    loading: () => <LoadingIndicator />,
  },
);

const RevokeContractForm = dynamic(() => import('@paid-ui/forms/revoke-contract'), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});

type Props = {
  children?: React.ReactNode;
  showHeader?: boolean;
  className?: string;
  hideCloseButton?: boolean;
  closeOnEscapeKeyDown?: boolean;
  closeOnClickOutside?: boolean;
};

const preventDefault = (event: Event) => event.preventDefault();

export const NestedFlyout: React.FC<Props> = ({
  children,
  hideCloseButton,
  showHeader,
  className,
  closeOnEscapeKeyDown = true,
  closeOnClickOutside = true,
}) => {
  const { nestedOpen, nestedType, nestedDetails } = useSnapshot(flyoutManager);
  const childrenComponent = useMemo(() => {
    switch (nestedType) {
      case NestedFlyoutType.VERIFY_MOBILE: {
        return <MobileVerificationForm />;
      }
      case NestedFlyoutType.EDIT_PERSONAL: {
        return <EditPersonalInfo />;
      }
      case NestedFlyoutType.EDIT_BUSINESS: {
        return <EditBusinessInfo />;
      }
      case NestedFlyoutType.EDIT_TRUSTEE: {
        return <EditTrusteesInfo />;
      }
      case NestedFlyoutType.EDIT_INDIVIDUAL_TRUSTEE: {
        return <EditIndividualTrustees />;
      }
      case NestedFlyoutType.EDIT_SIGNATORY: {
        const { relation } = nestedDetails ?? {};
        return <EditSignatoryForm relation={relation as UserGroupRelation} />;
      }
      case NestedFlyoutType.COMPLETE_PERSONAL: {
        return <CompletePersonalInfo />;
      }
      case NestedFlyoutType.COMPLETE_BUSINESS: {
        return <CompleteBusinessInfo />;
      }
      case NestedFlyoutType.COMPLETE_TRUSTEES: {
        return <CompleteTrusteesInfo />;
      }
      case NestedFlyoutType.SECUREPAID_DOC: {
        return <SecurePaidDoc />;
      }
      case NestedFlyoutType.EVIDENCE_MAP_VIEW: {
        return <EvidenceMapView />;
      }
      case NestedFlyoutType.RETENTION_CLAIM_DETAILS: {
        const { claimInfo } = nestedDetails ?? {};
        return <RetentionClaimDetails claimInfo={claimInfo} />;
      }
      case NestedFlyoutType.CLAIM_DETAILS_WITH_RETENTION: {
        const { claimAmount, retentionAmount } = nestedDetails ?? {};
        return (
          <ReviewClaimDetailsWithRetention
            claimAmount={claimAmount}
            retainAmount={retentionAmount}
          />
        );
      }
      case NestedFlyoutType.REVOKE_CONTRACT: {
        return <RevokeContractForm />;
      }
      default: {
        return children;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, nestedType]);

  if (showHeader) {
    return (
      <DrawerRoot open={nestedOpen} onOpenChange={closeNestedFlyout}>
        <DrawerPortal>
          <DrawerOverlay className="z-[502]" />
          <DrawerContent
            side="right"
            className={twMerge('z-[600] w-[752px]', className)}
            onEscapeKeyDown={closeOnEscapeKeyDown ? undefined : preventDefault}
            onInteractOutside={closeOnClickOutside ? undefined : preventDefault}
            onPointerDownOutside={closeOnClickOutside ? undefined : preventDefault}
          >
            <StepFormContainer
              currentStep={0}
              render={({ isHeaderSticky, isFooterSticky }) => (
                <>
                  <StepFormHeader sticky={isHeaderSticky} simple />
                  <StepFormContent className="max-w-full items-stretch justify-center gap-y-6">
                    {childrenComponent}
                  </StepFormContent>
                </>
              )}
            />
          </DrawerContent>
        </DrawerPortal>
      </DrawerRoot>
    );
  }

  return (
    <DrawerRoot open={nestedOpen} onOpenChange={closeNestedFlyout}>
      <DrawerPortal>
        <DrawerOverlay className="z-[502]" />
        <DrawerContent
          side="right"
          className={twMerge('z-[600] w-[752px]', className)}
          onEscapeKeyDown={closeOnEscapeKeyDown ? undefined : preventDefault}
          onInteractOutside={closeOnClickOutside ? undefined : preventDefault}
          onPointerDownOutside={closeOnClickOutside ? undefined : preventDefault}
        >
          {childrenComponent}
          <DrawerClose hidden={hideCloseButton} />
        </DrawerContent>
      </DrawerPortal>
    </DrawerRoot>
  );
};
